import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../../Functions/Functions';
import { getUserToken } from '../../../Session/Session';
import axios from '../../../Config/axios';
import './MyPaidExamLeaderBoard.css';
import { Container } from 'react-bootstrap';
import Loader from '../../../Loader/Loader';
const MyPaidExamLeaderBoard = () => {
    const [topThree,setTopThree]        = useState({});
    const [answers,setAnswers]          = useState({});
    const [module,setModule]            = useState({}); 
    const [isAnswers, setIsAnswers]     = useState(true);
    const [isShow, setISShow]           = useState(false);
    const navigate                      = useNavigate();
    const token                         = getUserToken();
    const { id }                        = useParams();
    const getAnswer = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/paid-exam-leaders/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                 
                if(res.data.error === false)
                {
                    setTopThree(res.data.data.top_3_answers);
                    setAnswers(res.data.data.answers);
                    setModule(res.data.data.topic);
                    setIsAnswers(false);
                }else{
                    setAnswers(false);
                    alertMessage('error','Error',res.data.message);
                }
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Paid Exam Leader Board | Nahid24.com";
        getAnswer();
        
    },[]);
    if(isAnswers) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
        );
    }
    
    return (
        <Container>
            <div className="leader-board-section">
                {
                    <>
                        <div className="exam-title">
                            <h3>{module.name}</h3>
                        </div>
                        {
                            topThree.length > 0 ? 
                                <div className='top-three-student'>
                                    <div className='top-three-items'>
                                        {
                                            topThree[1] ? <div className="top-three-item second-box">
                                                                <span>
                                                                    {topThree[1].image === null ? <div className="top-student-image-circle" title="user profile picture"></div> : <img src={topThree[1].image} />}
                                                                </span>
                                                                <span className="top-student-name">{topThree[1].name.split(' ')[0]}</span>
                                                                <span className="top-box">2</span>
                                                            </div> 
                                                        : ''
                                        }
                                        {
                                            topThree[0] ? <div className="top-three-item first-box">
                                                                <span>
                                                                    {topThree[0].image === null ? <div className="top-student-image-circle" title="user profile picture"></div> : <img src={topThree[0].image} />}
                                                                </span>
                                                                <span className="top-student-name">{topThree[0].name.split(' ')[0]}</span>
                                                                <span className="top-box">1</span>
                                                            </div> 
                                                        : ''
                                        }
                                        {
                                            topThree[2] ? <div className="top-three-item third-box">
                                                                <span>
                                                                    {topThree[2].image === null ? <div className="top-student-image-circle" title="user profile picture"></div> : <img src={topThree[2].image} />}
                                                                </span>
                                                                <span className="top-student-name">{topThree[2].name.split(' ')[0]}</span>
                                                                <span className="top-box">3</span>
                                                            </div> 
                                                        : ''
                                        }
                                        
                                    </div>
                                </div>
                                : 
                                ''
                        }
                        {
                            answers.length > 0 ?
                            <div className="leader-list">
                                {
                                    answers.map((answer,index) => { return(
                                        <div className="list-item" key={index}>
                                            <span>{index + 1}</span>
                                            <span>{answer.image === null ? <div className="profile-image-circle" title="user profile picture"></div> : <img src={answer.image} />}</span>
                                            <span>{answer.name}</span>
                                            <span>{answer.obtained_marks}</span>
                                        </div>
                                    )})
                                }
                            </div>
                            :
                            ''
                        }
                        
                    </>
                }
            </div>
        </Container>
    );
};

export default MyPaidExamLeaderBoard;