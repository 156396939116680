import React, { useEffect, useState } from 'react';
import './Forget.css';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import logo  from '../../../images/Nahid24-Logo-made-by-me-ai-2.png';
import forgetIcon  from '../../../images/Password-Recovery.png';
import axios from '../../Config/axios';
import { Link, useNavigate } from 'react-router-dom';
import { alertHtmlMessage, alertMessage } from '../../Functions/Functions';
import Loader from '../../Loader/Loader';
const Forget = () => {
    const [errors, setErrors]               = useState([]);
    const [forgetLoading, setForgetLoading] = useState(false);
    const [emailForm,setEmailForm]          = useState(true);
    const [otpForm,setOtpForm]              = useState(false);
    const [passwordForm,setPasswordForm]    = useState(false);
    const [otpCode,setOtpCode]              = useState('');
    const navigate                          = useNavigate();
    useEffect(() => {
        document.title = "Forget | Nahid24.com"
     }, []);
     const handelOtpForm = async (e) =>{
        e.preventDefault();
        setForgetLoading(true);
        setErrors('');
        const inputOtp = parseInt(e.target.code.value);
        if(otpCode === inputOtp)
        {
            setForgetLoading(false);
            alertMessage('success','Success','The Otp is matched.')
            setOtpForm(false);
            setPasswordForm(true);
        }else{
            setForgetLoading(false);
            alertMessage('error','Error','Invalid otp code')
        }
     }
     const handelEmailForm = async (e) =>{
        e.preventDefault();
        setForgetLoading(true);
        setErrors('');
        try {
            const email   = e.target.email.value;
            const res = await axios.post('/web/send-otp',{email:email});
            if(res.data.error === false)
            {
                setOtpCode(res.data.code);
                alertMessage('success','Success',res.data.message);
                setForgetLoading(false);
                setEmailForm(false);
                setOtpForm(true);
            }else{
                let errorMessageHml = '';
                const errorMessages = res.data.message;
                console.log(errorMessages);
                
                errorMessages.forEach((errorItem, index) => {
                    errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                });
                alertHtmlMessage('error','Error',errorMessageHml)
                // setErrors(res.data.message);
                setForgetLoading(false);
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
            setForgetLoading(false);
        }
     }
     const handelPasswordForm = async (e) =>{
        e.preventDefault();
        setForgetLoading(true);
        setErrors('');
        try {
            const email                   = e.target.email.value;
            const password                = e.target.password.value;
            const password_confirmation   = e.target.confirm_password.value;
            const res = await axios.post('/web/reset-password',{email:email,password:password,password_confirmation:password_confirmation});
            if(res.data.error === false)
            {
                alertMessage('success','Success',res.data.message);
                setForgetLoading(false);
                navigate('/login');
            }else{
                let errorMessageHml = '';
                const errorMessages = res.data.message;
                console.log(errorMessages);
                
                errorMessages.forEach((errorItem, index) => {
                    errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                });
                alertHtmlMessage('error','Error',errorMessageHml)
                // setErrors(res.data.message);
                setForgetLoading(false);
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
            setForgetLoading(false);
        }
     }
    return (
        <Container>
            <div className='forget-section'>
                <div className='forget-from'>
                    <Row className="justify-content-center align-items-center">
                        <Col md={6}>
                            <div className="title-logo">
                                <img src={forgetIcon} />
                            </div>
                            <div className="form-content">
                            <div className="forget-image">
                                    <img
                                        src={logo}
                                        width="80"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                        />
                                </div>
                                <p>
                                    আপনার সকল অর্ডার, কোনো কিছু কেনার রসিদসহ সকল কিছু নিরাপদে সংরক্ষণ করার জন্য আপনাকে অবশ্যই Sign In করতে হবে। Sign In করার জন্য কোনো একাউন্ট না থাকলে Sign Up করে নিন আথবা Facebook or Gmail দিয়ে Sign In করতে পারেন। 
                                </p>
                                
                                {
                                    emailForm ? 
                                    <Form className="mt-5" onSubmit={handelEmailForm}>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>
                                                    Email 
                                                    <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="email" name="email" placeholder="Enter Your Email" />
                                        </Form.Group>
                                        {
                                            errors.length > 0 ? 
                                            <div className="alert alert-warning" role="alert">
                                                {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                            </div>
                                            : ''
                                        }
                                        <div className='text-center mb-3'>
                                            {
                                                forgetLoading ? 
                                                <Button variant="primary" type="button">
                                                    <Loader/>
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit">
                                                    Send Code
                                                </Button>
                                            }
                                        </div>
                                    </Form>
                                    :
                                    ''
                                }
                                {
                                    otpForm ? 
                                    <Form onSubmit={handelOtpForm}>
                                        <Form.Group className="mb-3" controlId="code">
                                        <Form.Label>
                                            Verify Code 
                                                <span title="this field is required" className='text-danger'>*</span>
                                        </Form.Label>
                                            <Form.Control type="number" name="code" placeholder="Enter Your Code" />
                                            <Form.Text className="text-danger"></Form.Text>
                                        </Form.Group>
                                        {
                                            errors.length > 0 ? 
                                            <div className="alert alert-warning" role="alert">
                                                {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                            </div>
                                            : ''
                                        }
                                        <div className='text-center mb-3'>
                                            {
                                                forgetLoading ? 
                                                <Button variant="primary" type="button">
                                                    <Loader/>
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit">
                                                    Verify Code
                                                </Button>
                                            }
                                        </div>
                                    </Form>
                                    :
                                    ''
                                }
                                {
                                    passwordForm ? 
                                    <Form onSubmit={handelPasswordForm}>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>
                                                Email
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="email" name="email" placeholder="Your email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password">
                                            <Form.Label>
                                                New Password
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="password" name="password" placeholder="New Password" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="confirm_password">
                                            <Form.Label>
                                                Confirm Password
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control type="password" name="confirm_password" placeholder="Confirm Password" />
                                        </Form.Group>
                                        {
                                            errors.length > 0 ? 
                                            <div className="alert alert-warning" role="alert">
                                                {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                            </div>
                                            : ''
                                        }
                                        <div className='text-center mb-3'>
                                            {
                                                forgetLoading ? 
                                                <Button variant="primary" type="button">
                                                    <Loader/>
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            }
                                        </div>
                                    </Form>
                                    :
                                    ''
                                }
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className='me-3'>Already have an account? </span>
                                <Link to="/login">Sign In</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='videotutorial'>
                <Row className="videoContent">
                    <Col md={6}>
                        <div className='video'>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/pyyns0i_AXQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='guid-content'>
                            <h2>কিভাবে Password Recover করবেন?</h2>
                            <p>কিভাবে Password Recover করবেন তা বুঝতে পারছেন না?</p>
                            <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Forget;