import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { setUserInfo, setUserToken } from '../../Session/Session';
import { alertHtmlMessage, alertMessage } from '../../Functions/Functions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import axios from '../../Config/axios';
import logo  from '../../../images/Nahid24-Logo-made-by-me-ai-2.png';
import googleLogo  from '../../../images/sign-in-with-google.png';
import signIn  from '../../../images/sign-in.png';
import './Login.css';
import { getAuth, signInWithPopup,FacebookAuthProvider ,GoogleAuthProvider } from "firebase/auth";
import initializeFirebase from '../../../Firebase/Firebase.InitConfige';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
initializeFirebase();
const Login = () => {
    const [errors, setErrors]               = useState([]);
    const [loginLoading, setLoginLoading]   = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [facebookLoading, setFacebookLoading] = useState(false);
    const [passType,setPassType]                = useState('password');
    const navigate                              = useNavigate();
    const location                              = useLocation()
    const provider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    useEffect(() => {
        document.title = "Login | Nahid24.com";
     }, []);
     let state = false;
     if(location.state != null)
     {
        state = location.state.state;
     }
    const checkUserLog = async (newUser,loadingFunction) =>{
        try {
            const res = await axios.post('/web/check-user',newUser);
            if(res.data.error === false)
            {
                loadingFunction(false);
                setUserInfo(res.data.data.info);
                setUserToken(res.data.data.token);
                alertMessage('success','Success',res.data.message);
                if(state != false)
                {
                    navigate(state);
                }else{
                    navigate('/checkout');
                }
            }else{
                setErrors(res.data.message);
                loadingFunction(false);
            }
        } catch (error) {
            alertMessage('error','Error',error.message)
            loadingFunction(false);
        }
    }
    const handelGoogleSignin = async (e) =>{
        e.preventDefault();
        setGoogleLoading(true);
        try{
            const auth = getAuth();
            const googleLogin =  signInWithPopup(auth, provider)
             .then((result) => {
                 const user = result.user;
                 const provider     = user.providerData[0].providerId;
                 const providerId   = user.providerData[0].uid;
                 const newUser = {
                     name:user.displayName,
                     email:user.email,
                     number:user.phoneNumber,
                     image:user.photoURL,
                     provider:provider,
                     providerId:providerId,
                 }
                 checkUserLog(newUser,setGoogleLoading);
             })
        } catch (error) {
            alertMessage('error','Error',error.message)
            setGoogleLoading(false);
        }
        
    }
     const handelFacebookSignin = (e) =>{
        e.preventDefault();
        setFacebookLoading(true);
            try{
                const auth = getAuth();
                const facebookLogin =  signInWithPopup(auth, facebookProvider)
                .then((result) => {
                    const user = result.user;
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const accessToken = credential.accessToken;
                    console.log('user',user);
                    console.log('credential',user.providerData[0]);
                    let email = user.email;
                    let number = user.phoneNumber;
                    if(!email)
                    {
                        email = user.providerData[0].email;
                    }
                    if(!number)
                    {
                        number = user.providerData[0].phoneNumber;
                    }
                    const newUser = {
                        name:user.displayName,
                        email:email,
                        number:number,
                        image:user.photoURL,
                    }
                    checkUserLog(newUser,setFacebookLoading);
            })
        } catch (error) {
            alertMessage('error','Error',error.message)
            setFacebookLoading(false);
        }
     }
    const handelLoginSubmit = async (e) => {
        e.preventDefault();
        setLoginLoading(true);
        setErrors('');
        try {
            const email_or_number   = e.target.email_or_number.value;
            const password          = e.target.password.value;
            const res = await axios.post('/web/login',{email_or_number:email_or_number,password:password});
            if(res.data.error === false)
            {
                setUserInfo(res.data.data.info);
                setUserToken(res.data.data.token);
                alertMessage('success','Success',res.data.message);
                setLoginLoading(false);
                if(state != false)
                {
                    navigate(state);
                }else{
                    navigate('/checkout');
                }
            }else{
                let errorMessageHml = '';
                const errorMessages = res.data.message;
                errorMessages.forEach((errorItem, index) => {
                    errorMessageHml += `<p class="text-danger text-center m-0">${errorItem}</p>`;
                });
                alertHtmlMessage('error','Error',errorMessageHml)
                // setErrors(res.data.message);
                setLoginLoading(false);
            }
        } catch (error) {
            alertMessage('error','Error',error.message,10000)
            setLoginLoading(false);
        }
    }
    const handelPasType = () =>{
        if(passType == 'password')
        {
            setPassType('text');
        }else{
            setPassType('password');
        }
    }
    return (
        <Container>
            <div className='login-section'>
                
                <div className='login-from'>
                    <Row className="justify-content-center align-items-center">
                        <Col className="login-box m-auto" md={6}>
                        <div className='title-logo'>
                            <img src={signIn} />
                        </div>
                        <div className="text-center">
                            <p style={{color:"#00398c"}}>Sign in/Sign Up করতে ঝামেলা মনে হলে নিচে Order as a guest বাটনে ক্লিক করে সহজেই অর্ডার করতে পারেন। তবে Sign in/Sign Up করে অর্ডার করাই ভালো।</p>
                            <Button variant="primary" className="me-2 mb-2" type="button">
                                <Link to="/guest" style={{textDecoration:"none"}} className='text-white'>Order book as a guest</Link>
                            </Button>
                        </div>
                            <div className="login-form-content">
                                <div className="login-image">
                                    <img
                                        src={logo}
                                        width="80"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                        />
                                </div>
                                <p>
                                আপনার সকল অর্ডার, কোনো কিছু কেনার রসিদসহ সকল কিছু নিরাপদে সংরক্ষণ করার জন্য আপনাকে অবশ্যই Sign In করতে হবে। Sign In করার জন্য কোনো একাউন্ট না থাকলে, Sign Up বাটকে ক্লিক করে একাউন্ট খুলুন। আথবা দিয়ে Sign In with google বাটনে ক্লিক করুন।
                                </p>
                                    <Form className='mt-5' onSubmit={handelLoginSubmit}>
                                        <Form.Group className="mb-3" controlId="email_or_number">
                                            <Form.Label>
                                                Email or Mobile Number 
                                                <span title="this field is required" className='text-danger'>*</span>
                                            </Form.Label>
                                            <Form.Control  name="email_or_number" type="text" placeholder="Enter your email or mobile number" />
                                        </Form.Group>

                                        <Form.Group className="mb-3 p-relative" controlId="password">
                                            <Form.Label>
                                                Password 
                                                <span title="this field is required" className='text-danger'>*</span>
                                                
                                            </Form.Label>
                                            <Form.Control name="password" type={passType} placeholder="Enter your password" />
                                            <span className="password-eye" onClick={handelPasType}><FontAwesomeIcon icon={faEye} /></span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 remember-me" controlId="remember_me">
                                            <span>
                                                <Form.Check type="checkbox" label="Remember Me" />
                                            </span>
                                            <Link to="/forget">Forget Password?</Link>
                                        </Form.Group>
                                        
                                            {
                                                errors.length > 0 ? 
                                                <div className="alert alert-warning" role="alert">
                                                    {errors.map((errorItem,index) => {return(<p key={index} className="text-danger text-start m-0">{errorItem}</p>)})}
                                                </div>
                                                : ''
                                            }
                                       
                                        <div className='login-btn text-end'>
                                            {
                                                loginLoading ? 
                                                <Button variant="primary" type="button">
                                                    <Loader/>
                                                </Button>
                                                :
                                                <div>
                                                    <Button variant="primary" type="submit">
                                                        Sign In
                                                    </Button>
                                                </div>
                                            }
                                            
                                        </div>
                                    </Form>
                            </div>
                            <div className="signin-with">or Sign in with</div>
                            <div className="signin-other">
                                {
                                    googleLoading ?
                                    <Button variant="primary" type="button">
                                        <Loader/>
                                    </Button>
                                    :
                                    <Button className="other-btn" onClick={handelGoogleSignin}>
                                        <img src={googleLogo} />
                                    </Button>
                                }
                                {
                                    // facebookLoading ?
                                    // <Button variant="primary" type="button">
                                    //     <Loader/>
                                    // </Button>
                                    // :
                                    // <Button onClick={handelFacebookSignin}>Facebook Login</Button>
                                }
                                
                            </div>
                            <div>
                                <p className="signup-link">Don't have an account? <Link to="/register">Sign Up</Link></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='videotutorial'>
                <Row className="videoContent">
                    <Col md={6}>
                        <div className='video'>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Qi-6udAcZDU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='guid-content'>
                            <h2>কিভাবে Sign In করবেন?</h2>
                            <p>কিভাবে Sign In করবেন তা বুঝতে পারছেন না?</p>
                            <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>

    );
};

export default Login;