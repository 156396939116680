import Swal from "sweetalert2";

const createCartObject = (book,quantity) =>{
    let price = book.price;
    if(book.offer_price > 0 && book.is_offer == 1)
    {
        price = book.offer_price;
    }
    let newCart = {
        id          : book.id,
        name        : book.name,
        price       : price,
        photo       : book.photo,
        quantity    : quantity,
        is_cash     : book.cash_on_delivery == 1 ? true : false,
    };
    return newCart;
}
const createPaidExamCartObject = (item) =>{
    let newCart ={
        id:item.id
    }
    return newCart;
}
const notofication_forcash = (icon,title,message) =>{
    Swal.fire({
        icon: icon,
        title: title,
        text: message,
      })
}

const alertMessage  = (icon,title,message) =>{
    Swal.fire({
        icon: icon,
        title: title,
        text: message,
        timer: 1000
      })
}
const alertHtmlMessage  = (icon,title,message,timer = 50000) =>{
    Swal.fire({
        icon: icon,
        title: title,
        html: message,
        timer: timer
      });
}
export {    alertMessage,
            createCartObject,
            alertHtmlMessage,
            createPaidExamCartObject,
            notofication_forcash
        }