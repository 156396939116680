import React, { useEffect, useState } from 'react';
import axios from '../../../Config/axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../../Functions/Functions';
import { getUserToken } from '../../../Session/Session';
import './MyPaidExamTopics.css';
import Loader from '../../../Loader/Loader';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
const MyPaidExamTopics = () => {
    const [topics,setTopics]      = useState([]);
    const [isTopics, setIsTopics] = useState(true);
    const [subject,setSubject]    = useState('');
    const [title,setTitle]        = useState('');  
    const navigate              = useNavigate();
    const { id }                = useParams();
    const token                 = getUserToken();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'আমার পেইড পরীক্ষা',"url" : '/my-paid-exams',active:false},
    ]);
    const getSubjects = async () =>{
        if(token !== false)
        {
            try {
                const res = await axios.get(`/web/my-paid-exam-topics/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                  if(res.data.error == false){
                    if(res.data.data.topics.length > 0)
                    {
                        setBreadcrumbs([
                            ...breadcrumbs,
                            {name : res.data.data.exam_name,"url" : `/my-paid-exam/${res.data.data.exam_id}`,active:false},
                            {name : res.data.data.title+' / '+res.data.data.subject,"url" : '#',active:true},
                        ]);
                        setSubject(res.data.data.subject);
                        setTitle(res.data.data.title);
                        setTopics(res.data.data.topics);
                    }
                    
                  }else{
                    alertMessage('error','Error',res.data.message);
                  }
                  
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
        setIsTopics(false);
    }
    useEffect(() => {
        document.title = "Paid Exam Topics | Nahid24.com";
        getSubjects();
    },[]);
    const handelExamPage = (id) => {
        navigate(`/paid-exam/${id}`);
    }
    if (isTopics) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <Container>
            <h2 className="text-center my-2 page-title">আমার পেইড পরীক্ষা</h2>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            {
                topics.length > 0 ? 
                <div className="paid-exam-title-section">
                    <div className='paid-exam-title'>
                        <h2 className="text-center my-2 page-title">Topics</h2>
                        <Row>
                            {
                                topics.map((topic) => { return(
                                <Col xs={12} md={4} key={topic.id}>
                                    <div className="subject-box" onClick={() => handelExamPage(topic.id)}>
                                        <Link to={`/paid-exam/${topic.id}`}>{topic.name}</Link>
                                    </div>
                                </Col>
                                )})
                            }
                            
                        </Row>
                    </div>
                </div>
                :
                <div className="alert alert-warning text-center mt-3" role="alert">
                    Data not found!
                </div> 
            }
            
        </Container>
    );
};

export default MyPaidExamTopics;