import axios from '../../Config/axios';
import React, { useEffect, useState } from 'react';
import Book from './Book/Book';
import './Books.css';
import { Row } from 'react-bootstrap';
import Loader from '../../Loader/Loader';
const Books = () => {
    const [books,setBooks] = useState([]);
    const [error,setError] = useState(false);
    const [isBooks, setIsBooks] = useState(true);

    const getBooks = async () =>{
        try {
            const res = await axios.get('/product');
            setBooks(res.data.data);
            setIsBooks(false);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        getBooks();
    },[]);
    if (isBooks) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <div className='books-section'>
            <Row className='p-3'>
                {
                    books.map(book => <Book key={book.id} book={book}></Book>)
                }
            </Row>
            
        </div>
    );
};

export default Books;