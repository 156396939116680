import React, { useEffect } from 'react';

const NotFound = () => {
    useEffect(() => {
        document.title = "Page NotFound | Nahid24.com"
     }, []);
    return (
        <div>
            <h1>Page NotFound!</h1>
        </div>
    );
};

export default NotFound;