import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import axios from '../Config/axios';
import { alertMessage } from '../Functions/Functions';
const Layout = () => {
    const [headerCode,setHeaderCode] = useState(false);
    useEffect(()=>{
        
        get_header_content();
    },[]);
    const get_header_content = async () =>{
        try {
            const res = await axios.get(`/web/get-setting`);
            setHeaderCode(res.data.setting.header_code);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    // if(headerCode)
    // {
    //     document.head.append(headerCode);
    // }
    return (
        <div>
            <Header/>
            <Outlet />
            <Footer/>
        </div>
    );
};

export default Layout;