import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from '../../Config/axios';
import Loader from '../../Loader/Loader';
import './PaidCategories.css';
import PaidCategory from './PaidCategory/PaidCategory';
const PaidCategories = () => {
    const [categories,setCategories]        = useState([]);
    const [error,setError]                  = useState(false);
    const [isCategories, setIsCategories]   = useState(true);

    const getCategories = async () =>{
        try {
            const res = await axios.get('/web/paid-exam/categories');
            setCategories(res.data.data);
            setIsCategories(false);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        getCategories();
    },[]);
    if (isCategories) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <Container>
            <h2 className="text-center my-2 page-title">পেইড পরীক্ষা</h2>
            <div className='paid-exam-category-section'>
                <Row className="mt-4">
                    {
                        categories.map((category) => {return(<PaidCategory key={category.id} category={category} />)})
                    }
                </Row>
            </div>
            <div className='videotutorial'>
                <Row className="videoContent">
                    <Col md={6}>
                        <div className='video'>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/lF_gloGGLCk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='guid-content'>
                            <h2>কীভাবে ভর্তি হবেন?</h2>
                            <p>তা বুঝতে পারছেন না?</p>
                            <p>তাহলে পাশের Video Tutorial টি দেখতে পারেন। </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default PaidCategories;