import React, { useEffect, useState } from 'react';
import './Cart.css';
import axios from '../Config/axios';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../BreadcrumbComponent/BreadcrumbComponent';
import { Link, useNavigate } from 'react-router-dom';
import { getCartItems, getUserToken, updateCartItem } from '../Session/Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { alertMessage } from '../Functions/Functions';
import Swal from 'sweetalert2';
import Loader from '../Loader/Loader';

const Cart = () => {
    let TotalCartSum = 0;
    const navigate                      = useNavigate();
    let [cartItems,setCartItems]        = useState([]);
    let [shipping,setShipping]          = useState(0);
    let [others,setOthers]              = useState(0);
    let [cash,setCash]                  = useState(0);
    const [products,setProducts]        = useState([]);
    const [breadcrumbs]   = useState([
        {name : 'Home',"url" : '/',active:false},
        {name : 'Cart',"url" : '/cart',active:true},
    ])
    const getShiping = async () =>{
        try {
            const res = await axios.get('/web/delivery-charge');
            setOthers(res.data.data.others.amount);
            setCash(res.data.data.cash.amount);
            setShipping(res.data.data.others.amount);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    useEffect(()=>{
        document.title = "Cart | Nahid24.com";
        setCartItems(getCartItems());
        getShiping();
        get_products(getCartItems());
    },[]);
    const get_products = async (items) =>{
        try {
            const cartitems = {
                items:items
            }
            const token = getUserToken();
            const res =  await axios.post('/web/get-cart-products',cartitems,{
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              });
              setCartItems(res.data.products);
              setProducts(res.data.products);
              setShipping(res.data.shipping);
              console.log(res.data);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    const getTotalPrice = () =>{
        if(cartItems.length > 0)
        {
            const Totalsum = cartItems.reduce((Total, value) => {
                return Total + (parseFloat(value.price) * parseInt(value.quantity))
              }, 0);
              TotalCartSum = Totalsum;
              let sortedObjs = cartItems.sort((a, b) => a.id > b.id ? 1 : -1);
              cartItems = sortedObjs;
        }
    }
    getTotalPrice();
    let isCash = false;
    if(cartItems.length > 0)
    {
        for(let i = 0;i<cartItems.length;i++)
        {
            if(cartItems[i].is_cash == true)
            {
                isCash = true;
            }
        } 
    }
    const handelIncreaseQuantity = (id) => {
        const itemExist = cartItems.find(cartItem => cartItem.id === id);
        if(itemExist)
        {
            const cartFilter    = cartItems.filter(cartItem => cartItem.id !== id);
            const increase_quantity = itemExist.quantity + 1;
            itemExist.quantity  = increase_quantity;
            
            document.getElementById(`quantity_btn_${id}`).value = '';
            document.getElementById(`quantity_btn_${id}`).value = increase_quantity;
            const newCart       = [...cartFilter, itemExist];
            setCartItems(newCart);
            updateCartItem(newCart);
        }else{
            alertMessage('error','Error','Something Wrong');
        }
    }
    const handelDecreaseQuantity = (id) => {
        const itemExist = cartItems.find(cartItem => cartItem.id === id);
        if(itemExist)
        {
            if(itemExist.quantity > 1)
            {
                const cartFilter    = cartItems.filter(cartItem => cartItem.id !== id);
                itemExist.quantity  = itemExist.quantity - 1;
                const newCart       = [...cartFilter, itemExist];
                setCartItems(newCart);
                updateCartItem(newCart);
            }else{
                alertMessage('error','Error','Minimum 1 quantity is required');
            }
        }else{
            alertMessage('error','Error','Something Wrong');
        }
    }
    const handelInoutQuantity = (event,id) =>{
        const value = parseInt(event.target.value);
        if(value > 0)
        {
            const itemExist = cartItems.find(cartItem => cartItem.id === id);
            if(itemExist)
            {
                const cartFilter    = cartItems.filter(cartItem => cartItem.id !== id);
                itemExist.quantity  = value;
                const newCart       = [...cartFilter, itemExist];
                setCartItems(newCart);
                updateCartItem(newCart);
            }else{
                alertMessage('error','Error','Something Wrong');
            }
        }else{
            const itemExist = cartItems.find(cartItem => cartItem.id === id);
            if(itemExist)
            {
                const cartFilter    = cartItems.filter(cartItem => cartItem.id !== id);
                itemExist.quantity  = 0;
                const newCart       = [...cartFilter, itemExist];
                setCartItems(newCart);
                updateCartItem(newCart);
            }else{
                alertMessage('error','Error','Something Wrong');
            }
        }
        
    }
    const handelRemoveitem = (id) =>{
        const itemExist = cartItems.find(cartItem => cartItem.id === id);
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to delete?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
              actions: 'my-actions',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then((result) => {
            if (result.isConfirmed) {
                if(itemExist)
                {
                    const cartFilter    = cartItems.filter(cartItem => cartItem.id !== id);
                    const newCart       = [...cartFilter];
                    setCartItems(newCart);
                    updateCartItem(newCart);
                }else{
                    alertMessage('error','Error','Something Wrong');
                }
                navigate('/cart');
            }else{
                alertMessage('error','Error','Not Deleted!');
            }
          })
        
    }
    if(products.length == 0)
    {
        return <Loader/>
    }
    return (
        <Container>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div className='cart-section mt-3'>
                <Row>
                    {
                        cartItems.length > 0 ? 
                            <>
                                <Col md={8}>
                                
                                {
                                    cartItems.map(item => {
                                    return(
                                        <div key={item.id} className="cart-items px-4 py-3 mt-3 justify-content-between bg-white d-flex align-items-center">
                                            <div className='item-checkbox d-flex flex-column align-items-center'>
                                                <span className="item-trash"  onClick={() => handelRemoveitem(item.id)}><FontAwesomeIcon icon={faTrash} /></span>
                                            </div>
                                            <div className="item-image">
                                                <img src={item.photo}  alt=''/>
                                            </div>
                                            <div className="item-title">
                                                <h3>{item.name}</h3>
                                            </div>
                                            <div className='item-quantity'>
                                                <Button className="quantity-decrease" onClick={() => handelDecreaseQuantity(item.id)}>-</Button>
                                                {/* <Button variant="light" className="quantity-btn">{item.quantity}</Button> */}
                                                <input type="text" id={`quantity_btn_${item.id}`} className="quantity-btn" name="quantity" onChange={ (event) => handelInoutQuantity(event,item.id)} value={item.quantity} />
                                                <Button className="quantity-increase" onClick={() => handelIncreaseQuantity(item.id)}>+</Button>
                                            </div>
                                            <div className='item-price'>
                                                Tk.{item.price}
                                            </div>
                                        </div>
                                    )
                                    })
                                }
                                {
                                    shipping != 0 ?  
                                    <div className='selected-items  bg-white mt-2 text-end'>
                                    <h3 className='m-0' >Bkash/Nagad/Rocket এ send mony করলে Delivery Charge {others} টাকা
                                    </h3>
                                        {
                                            isCash ? 
                                            ''
                                            :
                                            <h3 className="mt-1"> Cash On Delivery তে Delivery {cash} টাকা</h3>
                                        }
                                        
                                    </div>
                                    :
                                    ''
                                }
                                
                                <div className='checkout-btn text-end mt-3 mb-3'>
                                    
                                    {/* <Button variant="primary" className="py-2 px-3"> */}
                                        <div className="checkout-btn-div">
                                            <Link className="btn btn-primary py-2 px-3" to="/checkout">Checkout</Link>
                                        </div>
                                    {/* </Button> */}
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='checkout-summary'>
                                    <h3>Checkout Summary</h3>
                                    <div className="summary-content">
                                        <div className='summery-item'>
                                            <span>SubTotal</span>
                                            <span>{TotalCartSum} Tk</span>
                                        </div>
                                        <div className='summery-item'>
                                            <span>Delivery Charge</span>
                                            {
                                                <span>{shipping} Tk</span>
                                            }
                                           
                                        </div>
                                        <div className='summery-item'>
                                            <span>Total</span>
                                            {
                                                isCash ? 
                                                <span>{TotalCartSum + shipping} Tk</span>
                                                :
                                                <span>{TotalCartSum + shipping} Tk</span>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            </>
                        :
                        <Col className="m-auto" md={10}>
                            <Alert  variant="warning">
                                Your cart is empty!
                            </Alert>
                        </Col>
                    }
                    
                </Row>
            </div>
        </Container>
    );
};

export default Cart;