import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from '../../../Config/axios';
import { alertMessage, createPaidExamCartObject } from '../../../Functions/Functions';
import Loader from '../../../Loader/Loader';
import parse from 'html-react-parser';
import './PaidExamNameDetails.css';
import sustainable from '../../../../images/sustainable.png';
import group from '../../../../images/group.png';
import repeat from '../../../../images/repeat.png';
import youtube from '../../../../images/youtube.png';
import { setPaidExamCartItems } from '../../../Session/Session';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
const PaidExamNameDetails = () => {
    const { id } = useParams();
    const [name,setName]            = useState([]);
    const [isName, setIsName]       = useState(true);
    const [category, setCategory]   = useState({});
    const [isClick,setClick]        = useState(false);
    const navigate                  = useNavigate();
    const [breadcrumbs,setBreadcrumbs]  = useState([
        {name : 'হোম',"url" : '/',active:false},
        {name : 'পেইড পরীক্ষা',"url" : '/paid-exam-category',active:false},
    ]);
    const getName = async (id) =>{
        try {
            const res = await axios.get(`/web/paid-exam/name/${id}`);
            setName(res.data.data.name);
            setCategory(res.data.data.category);
            setBreadcrumbs([
                ...breadcrumbs,
                {name : res.data.data.category.title,"url" : `/paid-exam-name/${res.data.data.category.id}`,active:false},
                {name : res.data.data.name.name,"url" : `#`,active:true},
            ]);
            setIsName(false);
        } catch (error) {
            alertMessage('error','Error',error.message);
        }
    }
    useEffect(() => {
        getName(id);
    },[]);
    const handelCheckout = (item)=>{
        setClick(true);
        setPaidExamCartItems(createPaidExamCartObject(item));
        navigate('/paid-exam/checkout');
    }
    if (isName) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    }
    return (
        <Container className="detail-section">
            <h2 className="text-center my-2 page-title"><Link to={`/paid-exam-name/${category.id}`}>{category.title}</Link></h2>
            <BreadcrumbComponent breadcrumbs={breadcrumbs} />
            <div>
            <Row className="mt-4 rowreverse">
                <Col className="colmodal">
                    <div className='courseintro'>
                        <div className="introvid">
                        <iframe width="100%" height="315" src={name.introduction_video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <div className="text-center py-3">
                                <h3>{name.name}</h3>
                                {
                                    isClick ? <button className="btn btn-primary"><Loader/></button>  : <button className="btn btn-primary" onClick={() => handelCheckout(name)}>ভর্তি হন</button>
                                }
                                
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="coldetails">
                    <h2 className='mt-4'>{name.name}</h2>
                    <div className="detail-content-box">
                        <span className='item-box sm-box'><a target="_blank" href={name.routine}><img src={sustainable} /> <small>রুটিন দেখুন</small> </a></span>
                        <span className='item-box big-box'><img src={group} /> <small>ভর্তি হয়েছেন {name.admitted_students} জন</small></span>
                    </div>
                    <div className="detail-content-box">
                        <span className='item-box sm-box'><img src={repeat} /> <small>মেয়াদঃ {name.course_duration}</small></span>
                        <span className='item-box big-box'><a href="https://www.youtube.com/playlist?list=PLrrnmJgAq2NoFrY3hgU-pHyqHxHKTCgwD" target="_blank"><img src={youtube} /> <small>ভিডিও টিউটোরিয়াল</small></a></span>
                    </div>
                </Col>
            </Row>
            </div>
            <Row className="name-details-section m-0">
                <Col md={12}>
                    <h3>বিস্তারিত</h3>
                    <div className="details-content">
                        {parse(name.details)}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PaidExamNameDetails;