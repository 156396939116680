import React, { useEffect, useState } from 'react';
import './LibraryList.css';
import parse from 'html-react-parser';
import axios from '../Config/axios';
import { Col, Container, Row } from 'react-bootstrap';
import { alertMessage } from '../Functions/Functions';
import Loader from '../Loader/Loader';
const LibraryList = () => {
    const [library,setLibrary]          = useState({});
    const [isLibrary, setIsLibrary]     = useState(true);

    const getLibrary = async () =>{
        try {
            const res = await axios.get(`/web/get-library-list`);
            if(res.data.error === false)
            {
                alertMessage('error','error',res.data.message)
            }else{
                setLibrary(res.data.library);
                setIsLibrary(false);
            }
        } catch (error) {
            alertMessage('error','error',error.message)
        }
    }
    useEffect(() => {
        document.title = "Library List | Nahid24.com";
        getLibrary();
    },[]);
    if (isLibrary) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='library-section'>
                            {parse(library)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LibraryList;