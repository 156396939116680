import React from 'react';
import Moment from 'moment';
import { Container } from 'react-bootstrap';
import logo  from '../../images/logo.png';
import appstore  from '../../images/app-store.png';
import emailIcon  from '../../images/fotter-email-icon.png';
import phoneIcon  from '../../images/fotter-call-icon.png';
import playstore  from '../../images/play.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './Footer.css';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <Container>
            <div className='footer-section'>
                <div className='download-section'>
                    <img className="logo-image" src={logo} alt="Nahid24" />
                    <h3>আমাদের মোবাইল অ্যাপ ডাউনলোড করুন</h3>
                    <span className="store-section">
                        <a href="https://play.google.com/store/apps/details?id=com.nahid24.android" target="_blank"><img src={playstore} alt='play store' /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.nahid24.android" target="_blank"><img src={appstore} alt='app store' /></a>
                        
                    </span>
                </div>
                <div className='extra-link'>
                    <ul>
                        <li><Link to="about">About Us</Link> </li>
                        <li><Link to="contact">Contact Us</Link> </li>
                        <li><Link to="policy">Privacy Policy</Link> </li>
                    </ul>
                </div>
                <div className="contact-section">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><img src={emailIcon} /><span>01787943429 (9AM to 9PM)</span></li>
                        <li><img src={phoneIcon} /><span>nahid24app@gmail.com</span></li>
                    </ul>
                </div>
            </div>
            <div className="main-footer">
            <div className='text-center mb-2'>
                    <a target="_blank" href="https://facebook.com/nahid24publications">Facebook</a>
                    <a target="_blank" href="https://instagram.com/nahid24publications">Instagram</a>
                    <a target="_blank" href="https://youtube.com/nahid24">Youtube</a>
                    <a target="_blank" href="https://tiktok.com/@nahid24publications">Tiktok</a>
                    <a target="_blank" href="https://wa.me/+8801787943429">Whatsapp</a>
                    <a target="_blank" href="https://nahidhasanmunna.com">Website</a>
                </div>
                <p>Copyright {Moment().format('YYYY')} @ Nahid24. | All Rights Reserved</p>
            </div>
        </Container>
    );
};

export default Footer;