import Moment from 'moment';
import './OrderReceipt.css';
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader/Loader';
import axios from '../../Config/axios';
import logo  from '../../../images/logo.png';
import signature  from '../../../images/signature.png';
import { alertMessage } from '../../Functions/Functions';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserToken } from '../../Session/Session';
import { Col, Container, Row } from 'react-bootstrap';
const OrderReceipt = () => {
    const [order,setOrder]      = useState([]);
    const [isOrder, setIsOrder] = useState(true);
    const navigate              = useNavigate();
    const { id } = useParams();
    let discount = 0;
    let checkTotalDiscount = 0;
    const getOrder = async () =>{
        if(getUserToken() !== false)
        {
            try {
                const token = getUserToken();
                const res = await axios.get(`/web/order/receipts/${id}`,{
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                  });
                setOrder(res.data.data);
                setIsOrder(false);
            } catch (error) {
                alertMessage('error','Error',error.message);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        document.title = "Order Receipt | Nahid24.com";
        getOrder();
    },[]);
    if (isOrder || !order) {
        return (
            <div className='books-section'>
                <Loader/>
            </div>
            
        );
    } 
    return (
        <Container>
            <div className="receipt-container my-2">
                <Row>
                    <Col md={10} className="m-auto">
                    <div className="receipt-section">
                    <header className="clearfix">
                        <div id="logo">
                            <img src={logo}   />
                        </div>
                        <div id="invoice">
                            <h1>INVOICE</h1>
                            <div className="to">ORDER NO: {id}</div>
                            <div className="status">Order Status: <strong>{order.order_status}</strong></div>
                            <div className="date">Invoice Date: {Moment().format('DD/MM/YYYY')}</div>
                        </div>
                    </header>
                    <main >
                        <div id="details" className='clearfix'>
                            <div id="company">
                                <h2 className="name">Sender</h2>
                                <div>Nahid24 Publications</div>
                                <div>Meherchandi, Koroitola</div>
                                <div>Behind Rajshahi University</div>
                                <div>Rajshahi</div>
                                <div>01787943429</div>
                                <div><a href="nahid24.com">nahid24.com</a></div>
                            </div>
                            <div id="client">
                                <h2 className="name">Receiver</h2>
                                <div className="address">Name           : {order.user_name}</div>
                                <div className="address">Full Address   : {order.address}</div>
                                <div className="address">Area/Upazila   : {order.upazila}</div>
                                <div className="address">Zila/District  : {order.district}</div>
                                <div className="address">Contact        : {order.number}</div>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table border="0" cellSpacing="0" cellPadding="0">
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th className="desc">ITEM DESCRIPTION</th>
                                    <th className="unit">RATE</th>
                                    <th className="qty">QUANTITY</th>
                                    <th className="total">AMOUNT</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        order.products.map((product,index) => {
                                            if(product.discount)
                                            {
                                                discount = product.discount;
                                            }
                                            return(
                                                
                                            <tr key={product.id}>
                                                <td>{index + 1}</td>
                                                <td className="desc">{product.product_name}</td>
                                                <td className="unit">
                                                    { 
                                                        (discount > 0) ? 
                                                            product.price - discount
                                                            :
                                                            product.price
                                                    }
                                                    </td>
                                                <td className="qty">{product.qty}</td>
                                                {
                                                    (discount > 0) ? 
                                                        <td className="total">{((product.price - discount) *  product.qty)}</td>
                                                    :
                                                    <td className="total">{(product.price *  product.qty)}</td>
                                                }
                                                
                                            </tr>
                                            )})
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td colSpan="2" className='title-bold'>DELIVERY CHARGE</td>
                                        <td className="text-center">{order.delivery_charge} TK</td>
                                    </tr>
                                    {
                                            (order.discount > 0 && discount == 0) ? 
                                            <tr>
                                                <td colSpan="2"></td>
                                                <td colSpan="2" className='title-bold'>Discount:</td>
                                                <td className="text-center">{order.discount} TK</td>
                                            </tr>
                                            :
                                                ''
                                    }
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td colSpan="2" className='title-bold'>Total</td>
                                       <td className="text-center">{parseInt(order.amount)} TK</td>
                                        
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div id="details" className="clearfix publisher-info">
                            <div id="company">
                                <div id="thanks">Thank you!</div>
                            </div>
                            <div id="client">
                                <div className='margin-left'>
                                    <img src={signature} className="signature" />
                                    <hr/>
                                    <p className='M-0'>Publisher, Nahid24</p>
                                </div>
                            </div>
                        </div>
                        <div className="notice-box">
                            <p id="notices">TERMS & CONDITIONS/NOTES:</p>
                            <div className="notice">You will receive the books by courier service within 3-5 working days. Remember,Sold products are not refundable. If the books is not collected from the courier on time, the book is returned. </div>
                        </div>
                    </main>
                    <footer className="bottom">Mehercandi, Koroitola, Rajshahi</footer>
                </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default OrderReceipt;