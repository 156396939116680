import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Loader = () => {
    return (
        <div>
            <Row>
                <Col md={12} className="text-center">
                    <div className="text-center">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Loader;