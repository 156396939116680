import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MyPaidExamItem.css';
const MyPaidExamItem = ({exam}) => {
    const {id,image,title,status} = exam;
    return (
        <Col xs={6} md={3} className="mb-4">
            {
                status == 1 ? 
                <Link to={`/my-paid-exam/${id}`} className="text-decoration-none category-link">
                    <div className="name-box">
                        <img src={image} alt={title}/>
                        <h3>{title}</h3>
                        <span className="name-price">Admitted</span>
                    </div>
                </Link>
                : 
                <div className="name-box">
                    <img src={image} alt={title}/>
                    <h3>{title}</h3>
                    <span className="name-price">{status == 0 ? 'Processing' : ((status == 3) ? 'Cancelled' : '')}</span>
                </div>
            }
            
        </Col>
    );
};

export default MyPaidExamItem;