import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

const CategoryNotification = (props) => {
    const { handleClose,show } = props;
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Notification!</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <Alert variant='warning'>
                    Please download our app on play store.
                </Alert>
                <a href="https://play.google.com/store/apps/details?id=com.nahid24.android" target="_blank">Downlaod</a>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                OK
            </Button>
            </Modal.Footer>
        </Modal>  
    );
};

export default CategoryNotification;