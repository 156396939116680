import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './Category.css';
import Library  from '../../images/Library List oct 2022.pdf';
import CategoryNotification from './CategoryNotification/CategoryNotification';
import { Link } from 'react-router-dom';
const Category = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow  = () => setShow(true);
    return (
        <>
            <div className='grid-container category-button'>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">ফ্রি কোর্স </Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">পেইড কোর্স </Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">লাইভ ক্লাস</Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">পরীক্ষা </Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">মডেল টেস্ট </Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">কুইজ</Button>
                </div>
                <div className="grid-item">
                    <Button  variant="primary"><Link to="/paid-exam-category">পেইড পরীক্ষা</Link></Button>
                </div>
                <div className="grid-item">
                    <Button onClick={handleShow} variant="primary">সাজেশন্স</Button>
                </div>
                <div className="grid-item">
                    <Button variant="primary">

                        {/* <a target="_blank" href={Library}>লাইব্রেরি</a> */}
                        <Link to="/library">লাইব্রেরি</Link>
                    </Button>
                </div>
            
            </div>
            <CategoryNotification handleClose={handleClose} handleShow={handleShow} show={show} />
        </>
    );
};

export default Category;